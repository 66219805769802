<template><div><h1 id="debugging" tabindex="-1"><a class="header-anchor" href="#debugging"><span>Debugging</span></a></h1>
<h2 id="grabbing-logs" tabindex="-1"><a class="header-anchor" href="#grabbing-logs"><span>Grabbing Logs</span></a></h2>
<ul>
<li>If you are experiencing firmware bugs, provide output from <code v-pre>cbmem</code> console.</li>
<li>If you are experiencing issues with Linux, provide output from <code v-pre>dmesg</code>, <code v-pre>dmidecode</code> and <code v-pre>journalctl</code> or <code v-pre>/var/log/messages</code>, respectively</li>
<li>If you are experiencing issues with Windows, provide screenshots from Device Manager, Event Viewer, coredumps etc.</li>
<li>If you are experiencing issues with macOS, please ask for help using the <a href="https://forum.chrultrabook.com/" target="_blank" rel="noopener noreferrer">Chrultrabook Forums</a>.</li>
</ul>
<h2 id="firmware" tabindex="-1"><a class="header-anchor" href="#firmware"><span>Firmware</span></a></h2>
<p>To debug firmware, you will need the following tools:</p>
<ol>
<li><code v-pre>cbmem</code> - To view Coreboot logs</li>
<li><code v-pre>ectool</code> - To interface with ChromeOS Embedded Controller</li>
<li>SuzyQ - If you are experiencing severe system instability (lockups, crashes), preventing you from on-device debugging.</li>
</ol>
<p>To dump the cbmem buffer, follow these steps:</p>
<ol>
<li>Download the tar archive from MrChromebox's website:
<ul>
<li><code v-pre>wget https://mrchromebox.tech/files/util/cbmem.tar.gz</code></li>
</ul>
</li>
<li>Unzip the file and give the binary executable permissions:
<ul>
<li><code v-pre>tar -xf cbmem.tar.gz;rm cbmem.tar.gz;chmod +x cbmem</code></li>
</ul>
</li>
<li>Run <code v-pre>cbmem</code> and pipe its output to <code v-pre>cbmem.log</code> file:
<ul>
<li><code v-pre>sudo ./cbmem -1 &gt; cbmem.log</code></li>
</ul>
</li>
<li>Upload <code v-pre>cbmem.log</code> file for further analysis.</li>
</ol>
<p>To interface with the Embedded Controller:</p>
<ol>
<li>Download the tar archive from MrChromebox's website:
<ul>
<li><code v-pre>wget https://mrchromebox.tech/files/util/ectool.tar.gz</code></li>
</ul>
</li>
<li>Unzip the file and give the binary executable permissions:
<ul>
<li><code v-pre>tar -xf ectool.tar.gz;rm ectool.tar.gz;chmod +x ectool</code></li>
</ul>
</li>
<li>Issue commands to EC by executing commands, for example setting the fan to 100%:
<ul>
<li><code v-pre>sudo ./ectool --name=cros_ec fanduty 100</code></li>
<li>Running <code v-pre>./ectool help</code> will provide you with a list of available commands, or you can <a href="https://chromium.googlesource.com/chromiumos/platform/ec/+/master/util/ectool.c#63" target="_blank" rel="noopener noreferrer">take a look at ectool's source code directly</a></li>
</ul>
</li>
</ol>
<h2 id="suzyq-debug-cable" tabindex="-1"><a class="header-anchor" href="#suzyq-debug-cable"><span>SuzyQ Debug Cable</span></a></h2>
<p>If you need to use SuzyQ, you can use <code v-pre>minicom</code> or <code v-pre>picocom</code> for example. It exposes three serial devices:</p>
<ol>
<li>AP (CR50) console under /dev/ttyUSB0:
<ul>
<li><code v-pre>minicom -D /dev/ttyUSB0 -b 115200</code></li>
</ul>
</li>
<li>Coreboot/platform serial under /dev/ttyUSB1:
<ul>
<li><code v-pre>minicom -D /dev/ttyUSB1 -b 115200</code></li>
</ul>
</li>
<li>Embedded Controller console under /dev/ttyUSB2
<ul>
<li><code v-pre>minicom -D /dev/ttyUSB2 -b 115200</code></li>
</ul>
</li>
</ol>
<ul>
<li>Platform serial can be used to debug the payload or kernel, but you need to re-compile coreboot with following configuration options enabled:
<code v-pre>CONSOLE_SERIAL=y</code>
<code v-pre>EDK2_SERIAL_SUPPORT=y</code></li>
<li>To use SuzyQ as platform debugger, you will also need to append the following to your kernel commandline:
<code v-pre>loglevel=15 console=ttyS4,115200n8</code></li>
</ul>
<h2 id="acpi-and-linux-kernelspace" tabindex="-1"><a class="header-anchor" href="#acpi-and-linux-kernelspace"><span>ACPI and Linux kernelspace</span></a></h2>
<ol>
<li>
<p>Download our debugging script.</p>
<ul>
<li><code v-pre>cd ~/Desktop;wget https://raw.githubusercontent.com/chrultrabook/linux-tools/main/debugging.sh</code></li>
</ul>
<p>It will dump:</p>
<ul>
<li>ACPI tables from sysfs (<code v-pre>/sys/firmware/acpi/*</code>)</li>
<li>DMI information (<code v-pre>dmidecode</code>)</li>
<li>Coreboot buffer (<code v-pre>cbmem</code>)</li>
<li>Linux kernel logs (<code v-pre>dmesg</code>)</li>
<li>List of PCI devices (<code v-pre>lspci</code>)</li>
<li>List of USB devices (<code v-pre>lsusb</code>)</li>
<li>Information about soundcards present in the system and their configuration</li>
</ul>
<p>Into <code v-pre>debug-logs.tar.gz</code> archive on your desktop.</p>
</li>
<li>
<p>Run it: <code v-pre>chmod +x debugging.sh;./debugging.sh</code></p>
</li>
<li>
<p>Upload this file if you need help with troubleshooting.</p>
<ul>
<li>Remember to remove WiFi information from dmesg to protect your privacy.</li>
</ul>
</li>
</ol>
<h2 id="flashrom" tabindex="-1"><a class="header-anchor" href="#flashrom"><span>flashrom</span></a></h2>
<p>Flashrom is used to read and write from the SPI flash</p>
<h3 id="read-flash" tabindex="-1"><a class="header-anchor" href="#read-flash"><span>Read flash</span></a></h3>
<ul>
<li><code v-pre>sudo flashrom -p &lt;programmer&gt; -r &lt;filename.rom&gt;</code></li>
<li>Example:
<ul>
<li><code v-pre>sudo flashrom -p internal -r backup.rom</code></li>
</ul>
</li>
</ul>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>If you have an Intel Chromebook, you will need to add <code v-pre>--ifd -i bios</code> if you want to write to the flash or else flashrom will fail.</p>
</div>
<h3 id="write-flash" tabindex="-1"><a class="header-anchor" href="#write-flash"><span>Write flash</span></a></h3>
<ul>
<li><code v-pre>sudo flashrom -p &lt;programmer&gt; -w &lt;filename.rom&gt;</code></li>
<li>Intel Example:
<ul>
<li><code v-pre>sudo flashrom -p internal --ifd -i bios -w stock.rom</code></li>
</ul>
</li>
<li>AMD Example:
<ul>
<li><code v-pre>sudo flashrom -p internal -w stock.rom</code></li>
</ul>
</li>
</ul>
<h3 id="write-protection" tabindex="-1"><a class="header-anchor" href="#write-protection"><span>Write protection</span></a></h3>
<p>View status:</p>
<ul>
<li><code v-pre>sudo flashrom -p internal --wp-status</code></li>
</ul>
<p>Enable WP:</p>
<ul>
<li><code v-pre>sudo flashrom -p internal --wp-enable</code></li>
</ul>
<p>Disable WP:</p>
<ul>
<li><code v-pre>sudo flashrom -p internal --wp-disable</code></li>
</ul>
<p>Clear WP range:</p>
<ul>
<li><code v-pre>sudo flashrom -p internal --wp-range 0 0</code></li>
</ul>
<p>Common programmers:</p>
<ul>
<li><code v-pre>internal</code>: Use this when you run flashrom on the chromebook you want to program.</li>
<li><code v-pre>ch341a_spi</code>: Use this when you use a ch341a external programmer.</li>
<li><code v-pre>raiden_debug_spi:target=AP</code>: Use then when you want to flash using a Suzy-Q cable.</li>
</ul>
<h2 id="gsctool" tabindex="-1"><a class="header-anchor" href="#gsctool"><span>gsctool</span></a></h2>
<p>gsctool is used to communicate with the GSC (Google Security Chip) from ChromeOS and is usually used to unlock CCD without removing the back of the Chromebook.</p>
<p>View status:</p>
<ul>
<li><code v-pre>sudo gsctool -a -I</code></li>
</ul>
<p>Unlock CCD:</p>
<ul>
<li><code v-pre>sudo gsctool -a -o</code></li>
<li>This will prompt you multiple times to press the power button, on the last time, the device will reboot back into verified mode</li>
</ul>
</div></template>


